<template>
  <div class="order">
    <van-nav-bar  left-arrow  :safe-area-inset-top="true" fixed :border="false" @click-left="goBack"
                  class="nav" >
      <template #title>
        <p class="title">转赠记录</p>
      </template>

    </van-nav-bar>

      <div class="main-body">
        <div class="content-tabs">
          <van-tabs @click-tab="clickTab"  v-model:active="tabActive" shrink sticky line-width="43px" line-height="3px" title-active-color="#313D4A" title-inactive-color="#7A8087">
            <van-tab v-for="item in tabArray" :title="item.title" :name="item.idx" :key="item.idx">

            </van-tab>
          </van-tabs>

        </div>

        <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="refresh-body">
          <van-list v-if="firstShow || orderList.length > 0" v-model:loading="loading" v-model:error="error" :finished="finished" finished-text="已经到底了"
                    error-text="加载出错,请重新加载" @load="onLoad">
            <ul class="goods-content">
              <li v-for="(item, index) in orderList" :key="index" >
                <div class="line"></div>
                <!-- <router-link tag="div" :to="{ path: '/nftDetail', query: { productId: item.id } }" class="goods-img">
            </router-link> -->

                  <div class="order-items" @click="goToOrderDetail(item)">
                      <van-image class="product-img" height='72px' width="72px" radius="8px" :src="item.image">
                      </van-image>
                    <div class="left-info">
                      <div class="left-info-1">
                        <p class="product-name">{{ item.productName }}</p>
                        <p v-if = "item.isTransfer == 2" class="order-state-f">转入</p>
                        <p v-else= "item.isTransfer == 1" class="order-state">转出</p>

                      </div>
                      <div class="other">
                         <div class="other-left">
                           <div class="div-number">
                             <div class="left-chain-logo">
                               <van-image :src="require('../../assets/mine/icon_mine_chain.png')" class="goods-bottom-img"  >
                               </van-image>
                             </div>
                             <p class="ntf-number">{{  item.number}}</p>
                           </div>
                           <p class="zz-time">{{item.transferTime}}</p>
                         </div>
                        <div class="other-right" >
                          <van-image :src="require('../../assets/common/icon_arrow.png')" fit="fill" width="24px"></van-image>
                        </div>
                      </div>


                    </div>




                  </div>

              </li>
            </ul>

          </van-list>

          <div v-else class="nodata">
            <img src="@/assets/common/icon_nodata.png" alt="">
            暂无转赠记录
          </div>
        </van-pull-refresh>
      </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import Certification from "@/components/certification";
import TopBar from "@/components/topBar";
import { clearToast, createNonceStr, showFailToast, showLoading } from "../../utils/util";
export default {
  name: "tranformOrder",
  setup() {
    const { proxy } = getCurrentInstance();
    const $router = useRouter();
    const state = reactive({
      tabActive:0,
      orderList: [],
      tabArray: [
        {
          title: "全部",
          idx: 0
        },
        {
          title: "转入",
          idx: 2
        },
        {
          title: "转出",
          idx: 1
        }],
      loadingMore: false,
      loading:false,
      finished:false,
      refreshing:false,
      error:false,
      offset: 0,
      limit: 10,
      firstShow:true
    });
    onMounted(async () => {
    });
    const chooseTilte = ((index) => {
      state.offset = 0;
      state.limit = 5
      state.orderList=[]
      onLoad()
    })
    const onLoad = (() => {
      loadData();
    })

    const loadData = () => {
      console.log(state.tabActive);
      showLoading();
      proxy.$http.get("/wenwu-order/order/transfer/list?isTransfer=" + state.tabActive + "&offset=" + state.offset + "&limit=" + state.limit).then(res => {
        clearToast()
        if (res.data.code == 200) {
          state.firstShow = false
          clearToast()
          if(state.offset == 0){
            state.orderList=[];
          }
          state.orderList.push(...res.data.data);

          state.finished = false
          state.loading = false
          if (res.data.data.length < 10) {
            state.finished = true
          } else {
            state.offset += state.limit
          }
        } else {
          showFailToast(res.data.message)
          state.error = true;
          state.finished = false;
          state.loading = false;
        }
      }).catch((err)=>{
        clearToast()
        showFailToast(err.message)
      })
    }
    const onRefresh = (() => {
      state.offset = 0;
      state.finished = true
      state.loading = true
      state.refreshing = false
      loadData()
    })

    const clickTab = () => {
      state.offset = 0;
      state.finished = true
      state.loading = true
      state.refreshing = false
      loadData();

    }

    const goBack = (() => {
      $router.back(-1)
    })

    const goToOrderDetail = (item) => {


        $router.push({name:'tranformDetail',query:{'id':item.id,'isTransfer':item.isTransfer}})


    }

    return {
      goBack,
      clickTab,
      ...toRefs(state),
      chooseTilte,
      onLoad,
      onRefresh,
      goToOrderDetail
    };
  }
};
</script>

<style scoped lang="scss" src="./index.scss">

</style>


